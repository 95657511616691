<template>
  <CabinHero v-if="cabinUserRoleId" :cabin="cabin" :cabinUserRoleId="cabinUserRoleId"></CabinHero>
  <section class="cabin-calendar" id="cabin-calendar">
    <BookingDialog ref="bookingDialog"></BookingDialog>
    <BookingEditDialog ref="bookingEditDialog"></BookingEditDialog>
    <ConfirmationDialog ref="confirmationDialog"></ConfirmationDialog>
    <div class="cabin-calendar_notations">
      <span class="notation-me">Meg</span>
      <span class="notation-other">Andre</span>
    </div>
    <div class="cabin-calendar_grid">
      <row container :gutter="20">
        <column :xs="12" :md="12" :lg="12">
          <Calendar
            locale="nb-NO"
            @dayclick="deleteOrSelectBooking"
            ref="calendar"
            class="custom-calendar"
            title="MM"
            is-expanded
            trim-weeks
            show-iso-weeknumbers
            :min-date='startDate'
            :columns="$screens({ default: 1, lg: 2 })"
            :attributes="entries"
            :firstDayOfWeek="firstDayOfWeek"
          >
          </Calendar>
        </column>
      </row>
    </div>
  </section>
</template>
<script>
import CabinHero from '@/components/CabinHero';
import BookingService from '@/services/BookingService';
import ConfirmationDialog from '@/components/modals/ConfirmationDialog';
import BookingDialog from '@/components/modals/BookingDialog';
import BookingEditDialog from '@/components/modals/BookingEditDialog';
import Helpers from '@/helpers/helpers';
import { mapGetters } from 'vuex';
import store from '@/store';

export default {
  components: {
    ConfirmationDialog,
    BookingDialog,
    BookingEditDialog,
    CabinHero
  },
  props: {
    cabinUserRoleId: Number,
    cabin: Object
  },
  data() {
    return {
      createBookingMode: false,
      editBookingMode: false,
      editingBookingId: undefined,
      clickedDate: null,
      firstDayOfWeek: 2,
      bookings: [],
      selectedDates: {
        from: undefined,
        to: undefined
      },
    };
  },
  computed: {
    ...mapGetters(['users/userId', 'users/firstName', 'users/lastName']),
    entries() {
      let bookedDates = this.bookings.map(item => {
        return {
          key: item.id,
          highlight: {
            fillMode: 'solid',
            style: {
              background: item.cabinUserId === this['users/userId'] ? '#B2557F' : '#5398A7',
            },
            class: 'day-highlight'
          },
          dates: {
            start: new Date(item.fromDateUtc),
            end: new Date(item.toDateUtc)
          },
          customData: item
        };
      });

      let selectedDates = [];

      if(this.selectedDates.from || (this.selectedDates.to && this.selectedDates.from)) {
        selectedDates.push(
          {
            key: 'selected',
            highlight: {
              fillMode: 'solid',
              style: {
                background: '#E9E9E9',

              },
              class: 'selected-highlight',
              contentClass: 'selected-content-highlight'
            },
            dates: {
              start: new Date(this.selectedDates.from),
              end: this.selectedDates.to ? new Date(this.selectedDates.to) : new Date(this.selectedDates.from) 
            }
          }
        )
      }

      return bookedDates.concat(selectedDates);
    },
    startDate() {
      var currentDate = new Date();

      currentDate.setFullYear(currentDate.getFullYear() - 5);
      
      return currentDate;
    }
  },
  methods: {
    fromUTC: Helpers.fromUTC,
    convertDateIgnoresTimezone: Helpers.convertDateIgnoresTimezone,
    backMonth() {
      this.$refs.calendar.move(-1);
    },
    forwardMonth() {
      this.$refs.calendar.move(1);
    },
    isCabinOwner() {
      if (this.cabinUserRoleId === 1) return true;

      return false;
    },
    isCabinAdministrator() {
      if (this.cabinUserRoleId === 2) return true;

      return false;
    },
    isCabinUser() {
      if (this.cabinUserRoleId === 3) return true;

      return false;
    },
    toggleDatepicker() {
      this.datepickerActive = !this.datepickerActive;
    },
    toTimeStamp(date) {
      var datum = Date.parse(date);
      return datum / 1000;
    },
    validateBookingDateRanges(startDate, endDate) {
      let valid = true;

      for(let i = 0; i < this.bookings.length; i++) {
        let booking = this.bookings[i];
        if(new Date(booking.fromDateUtc) > new Date(startDate) 
          && new Date(booking.fromDateUtc) < new Date(endDate)) {
          valid = false;
          break;
        }
      }

      return valid;
    },
    setBookingDates(val) {
      if(!this.selectedDates.from || this.selectedDates.from > val) {
        this.selectedDates.from = val;
      } else if(!this.selectedDates.to) {
        this.selectedDates.to = val;
      } else {
        this.selectedDates.from = val;
        this.selectedDates.to = undefined;
      }
    },
    deleteOrSelectBooking(day) {
      let invalidDate = false;
       let userId = undefined;
      for(let i = 0; i < this.bookings.length; i++) {
        let booking = this.bookings[i];

        if(new Date(booking.fromDateUtc).getTime() <= new Date(day.date).getTime() 
          && new Date(booking.toDateUtc).getTime() >= new Date(day.date).getTime()) {
            invalidDate = true;
            userId = booking.cabinUserId;
            break;
        }
      }
      if(this.createBookingMode) {
        this.createBooking(day);
      } else if(this.editBookingMode && this.editingBookingId !== undefined) {
        this.editBooking(day);
      } else if(invalidDate) {
        this.editingBookingId = day.attributes[0].customData.id;
        this.editBooking(day.attributes[0].targetDate, day.attributes[0].customData.cabinUserFirstName, userId);
      } else {
        this.createBooking(day);
      }
    },
    async editBooking(val, name, userId) {
      this.$refs.bookingDialog.hide();
      if(this.editBookingMode) {
        this.setBookingDates(val.date);
      } else { 
        this.selectedDates.from = val.start;
        this.selectedDates.to = val.end;
      }
      this.editBookingMode = true;
      const bookingData = await this.$refs.bookingEditDialog.show({
        title: 'Rediger booking',
        message: '',
        okButton: 'Rediger',
        deleteButton: 'Slett',
        cancelButton: true,
        name: name,
        from: this.selectedDates.from,
        to: this.selectedDates.to,
      });
      if (bookingData && bookingData.delete === false) {
        const startDate = this.convertDateIgnoresTimezone(this.selectedDates.from);
        const endDate = this.convertDateIgnoresTimezone(this.selectedDates.to);

        try {
          const { data } = await BookingService.updateBooking(
            this.editingBookingId,
            startDate,
            endDate,
            bookingData.persons,
            this.isCabinOwner() || this.isCabinAdministrator() ? 2 : 1,
            [{
              contentTypeId: 3,
              text: bookingData.comment,
              contentLanguageId: 1
            }],
            this.isCabinOwner() || this.isCabinAdministrator() ? false : true
          );
          this.bookings.push(data);
          this.$toast.success(`Reservasjon oppdatert`);

        } catch {
          this.$toast.error(`Kunne ikke redigere reservasjon`);
        }
      } else if(bookingData && bookingData.delete === true) {
        if(!this.canDelete(userId)) return;
        try {
          await BookingService.deleteBooking(this.editingBookingId);
          let index = this.bookings.findIndex(item => {
            return item.id === this.editingBookingId;
          });
          this.bookings.splice(index, 1);
          this.$toast.success(`Reservasjon slettet`);
        } catch {
          this.$toast.error(`Kunne ikke slette reservasjonen`);
        } 
      }
      this.editBookingMode = false;
      this.clearSelected();
    },
    async createBooking(val) {
      this.$refs.bookingEditDialog.hide();
      this.setBookingDates(val.date);
      this.createBookingMode = true;

      const bookingData = await this.$refs.bookingDialog.show({
        title: 'Ny booking',
        message: 'Velg dato for å booke hytten',
        okButton: 'Send forespørsel',
        cancelButton: true,
        from: this.selectedDates.from,
        to: this.selectedDates.to,
      })
      if (bookingData) {
        const startDate = this.convertDateIgnoresTimezone(this.selectedDates.from);
        const endDate = this.convertDateIgnoresTimezone(this.selectedDates.to);
  
        try {
          if(!this.validateBookingDateRanges(startDate, endDate)) {
            this.$toast.error(`Hytten er opptatt i dette tidsrommet`);
            return;
          }

          const { data } = await BookingService.addBooking(
            this.cabin.id,
            startDate,
            endDate,
            bookingData.persons,
            this.isCabinOwner() || this.isCabinAdministrator() ? 2 : 1,
            [{
              contentTypeId: 3,
              text: bookingData.comment,
              contentLanguageId: 1
            }],
            this.isCabinOwner() || this.isCabinAdministrator() ? false : true
          );
          this.bookings.push(data);
          this.$toast.success(`Reservasjon lagt til`);
        } catch {
          this.$toast.error(`Kunne ikke legge til reservasjon`);
        }
      } else {
        console.log('Kunne ikke legge til reservasjon');
      }
      this.createBookingMode = false;
      this.clearSelected();
    },
    canDelete(val) {
      if(this.isCabinOwner() || this.isCabinAdministrator()) {
        return true;
      }
      
      if(this['users/userId'] === val) {
        return true;
      }

      return false;
    },
    clearSelected() {
      this.selectedDates.from = undefined;
      this.selectedDates.to = undefined;
      this.editingBookingId = undefined;
    }
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('utilities/getBookingStatuses');
    const { data } = await BookingService.getBookings(to.params.cabinId);
    next(vm => {
      if (data) {
        vm.bookings = data;
      }
    });
  }
};
</script>
<style lang="scss" scoped>
.blue {
  background-color: #83ddf1;
}
.red {
  background-color: #e0a4bf;
}
.grey {
  background-color: $light-grey;
}
.cabin-calendar {
  margin: 2rem 0;
  padding-bottom: 6rem;
  @media (max-width: $breakpoint-mobile) {
    overflow-x: hidden;
    background-color: $bodybg;
    width: 100%;
    h1 {
      padding: 1rem 0 0 1rem;
    }
  }
  @media (min-width: $breakpoint-mobile) {
    margin: 2rem 0;
    @include gutter-padding;
  }
  &_notations {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    color: white;
    span {
      border-radius: 8px;
    }
    .notation-me {
      margin-right: 1rem;
      background-color: #B2557F;
      padding: .5rem;
    }
    .notation-other {
      background-color: #5398A7;
      padding: .5rem;
    }
  }
  
  :deep(.custom-calendar.vc-container) {
    --weekday-bg: #f8fafc;
    --color: black;
    --rounded-full: 5px;
    --font-bold: 600;
    border: none;
    .vc-pane-container {
      background-color: $bodybg;
    }
    .vc-header {
      background-color: $bodybg;
      padding: 10px 0;
    }
    .vc-arrows-container {
      .is-left, .is-right {
        background-color: #f8fafc;
        color: $dark-grey;
      }
    }
    .vc-weeks {
      padding: 0;
      background-color: $bodybg;
      grid-auto-rows: 1fr;
    }
    .vc-weekday {
      background-color: $bodybg;
      padding: 10px 0;
      font-size: 9px;
    }
    .vc-weeknumber .vc-weeknumber-content {
      font-size: 9px;
    }
    .vc-day {
      display: flex;
      justify-content: center;
      padding: .75rem;
      font-size: 100px;
    }
    .vc-highlight {
      height: 40px;
    }
    .selected-content-highlight {
      color: black!important;
    }
    .selected-content-highlight:focus, .selected-content-highlight:hover {
      background-color: transparent;
    }
    .vc-day.on-right {
      margin-right: 0;
    }
    .vc-day.on-left {
      margin-left: 0;
    }
  }
}
#cabin-calendar.drawer-modal-active-content {
  @media (max-width: $breakpoint-mobile) {
      padding-bottom: 20rem;
  }
}
</style>
