<template>
  <big-drawer-modal ref="popup">
    <div class="cancel-button">
      <Close v-if="cancelButton" @click="cancel()" />
    </div>
    <h2 style="margin-top: 0">{{ title }} ({{ name }})</h2>
    <div class="inputs">
      <input :value="formatDate(bookingData.from)" type="text" id="start" placeholder="Innsjekk" disabled>
      <input :value="formatDate(bookingData.to)" type="text" id="end" placeholder="Utsjekk" disabled>
    </div>
    <div class="persons-choice">
      <span 
        @click="decrementPersons()"
        class="persons-choice-minus"
      >&#8722;</span>
        {{ bookingData.persons }} personer
      <span
        @click="incrementPersons()"
        class="persons-choice-plus"
      >&#43;</span>
    </div>
    <div class="comment">
      <textarea @focus="scrollToBottom()" v-model="bookingData.comment" name="comment" id="comment" placeholder="Kommentar"></textarea>
    </div>
    <div class="btns">
      <Button color="green" :text="okButton" @click="confirm()"></Button>
      <Button color="red" :text="deleteButton" @click="remove()"></Button>
    </div>
  </big-drawer-modal>
</template>
<script>
import BigDrawerModal from './BigDrawerModal'
import Button from '@/components/Button';
import Close from '@/assets/close.svg';
import Helpers from '@/helpers/helpers';

export default {
  name: 'BookingEditDialog',
  components: { 
    BigDrawerModal,
    Button,
    Close
  },
  data: () => ({
    title: undefined,
    message: undefined,
    okButton: undefined,
    cancelButton: undefined,
    deleteButton: undefined,
    name: undefined,
    bookingData: {
      from: undefined,
      to: undefined,
      persons: undefined,
      comment: undefined,
      delete: undefined
    }
  }),
  methods: {
    formatDate: Helpers.formatDate,
    show(opts = {}) {
      this.title = opts.title
      this.message = opts.message
      this.okButton = opts.okButton
      this.deleteButton = opts.deleteButton,
      this.name = opts.name,
      this.bookingData.from = opts.from
      this.bookingData.to = opts.to
      this.bookingData.persons = 1
      this.bookingData.comment = undefined
      if (opts.cancelButton) {
        this.cancelButton = opts.cancelButton
      }
      this.$refs.popup.open()

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    hide() {
      this.clearForm()
      this.$refs.popup.close()
    },
    decrementPersons() {
      if (this.bookingData.persons > 1) this.bookingData.persons -= 1;
    },
    incrementPersons() {
      this.bookingData.persons += 1;
    },
    confirm() {
      this.$refs.popup.close()
      this.bookingData.delete = false;
      this.resolvePromise(this.bookingData)
    },
    remove() {
      this.$refs.popup.close()
      this.bookingData.delete = true;
      this.resolvePromise(this.bookingData)
    },
    cancel() {
      this.$refs.popup.close()
      this.bookingData.delete = false;
      this.resolvePromise(false)
      this.clearForm();
    },
    clearForm() {
      this.bookingData.from = undefined;
      this.bookingData.to = undefined;
      this.bookingData.comment = undefined;
    },
    scrollToBottom() {
      window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
    }
  },
}
</script>
<style lang="scss" scoped>
  .cancel-button {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  h2 {
    @media (min-width: $breakpoint-tablet) {
      padding-top: 0;
      margin: 0;
    }
    
  }
  .inputs, .btns, .comment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-bottom: 1rem;
    @media (min-width: $breakpoint-tablet) {
      margin-bottom: 0;
    }
    input {
      text-align: center;
      font-weight: 500;
    }
    .button {
      width: 100%;
    }
  }
  .persons-choice {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    @media (max-width: $breakpoint-tablet) {
      margin-bottom: 1rem;
    }
    .persons-choice-minus,
    .persons-choice-plus {
      background-color: $light-grey;
      padding: 0 0.75rem;
      font-size: 2rem;
      border-radius: 50%;
      cursor: pointer;
    }
    .persons-choice-minus {
      margin-right: 1rem;
    }
    .persons-choice-plus {
      margin-left: 1rem;
    }
  }
  .comment {
    flex-grow: 1;
    textarea {
      width: 100%;
      height: 42px;
      background-color: rgba(239, 239, 239, 0.3);
    }
  }
</style>