<template>
    <Transition name="slide" @enter="onEnter" @leave="onLeave">
        <div class="big-drawer-modal" v-if="isVisible">
            <div class="window">
                <div class="window-inner">
                    <slot></slot>
                </div>
            </div>
        </div>
    </Transition>
</template>
<script>
import vClickOutside from 'click-outside-vue3'

export default {
    name: 'BigDrawerModal',
    data: () => ({
        isVisible: false,
    }),
    directives: {
      clickOutside: vClickOutside.directive
    },
    methods: {
        open() {
            this.isVisible = true
        },
        close() {
            this.isVisible = false
        },
        onEnter() {
          const hero = document.getElementById('cabinHero');
          const content = document.getElementById('cabin-calendar');
 
          content.classList.add('drawer-modal-active-content');
          hero.classList.add('drawer-modal-active');
        },
        onLeave() {
          const hero = document.getElementById('cabinHero');
          const content = document.getElementById('cabin-calendar')
 
          content.classList.remove('drawer-modal-active-content');
          hero.classList.remove('drawer-modal-active');
        },
    },
}
</script>
<style lang="scss" scoped>
  .big-drawer-modal {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: 9999;
    overflow-y: hidden;
  }
  .window {
    position: relative;
    display: flex;
    background: #fff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    @media (min-width: $breakpoint-tablet) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      padding: 2.5rem 0;
    }
    justify-content: center;
    align-items: center;
    align-self: bottom;
    &-inner {
      @include gutter-padding;
      @media (min-width: $breakpoint-tablet) {
        width: 1280px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        gap: 10px;
      }
    }
  }
  .slide-enter-active {
    transition: all 0.5s ease;
    transform: translateY(90%);
    opacity: 1;
  }

  .slide-enter-to {
    transition: all 0.5s ease;
    transform: translateY(0%);
  }

  .slide-leave-active {
    transition: all 0.5s ease;
    transform: translateY(30%);
    opacity: 0;
  }
</style>
